<template>
  <b-container fluid>
    <form @submit.prevent="saveData">
      <b-row>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle>
              <h4 class="card-title"><b>Abrir Ticket</b></h4>
            </template>
            <div class="row px-5 mt-3">
              <div class="col-md-9">
                <div class="mb-3">
                  <label for="asunto" class="form-label">Asunto</label>
                  <input required v-model="item.General.Asunto" placeholder="Escriba el Asunto..." type="text" class="form-control" id="asunto" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-3">
                  <label for="prioridad" class="form-label">Prioridad</label>
                  <b-form-select id="prioridad" :options="ListaPrioridad" required v-model="item.General.Prioridad" value-field="id" text-field="nombre"></b-form-select>

                  <!-- <select required class="form-control" id="prioridad">
                    <option value="alta">Alta</option>
                    <option value="media">Media</option>
                    <option value="baja">Baja</option>
                  </select> -->
                </div>
              </div>
            </div>
            <div class="row px-5 mt-3">
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="mensaje" class="form-label">Mensaje</label>
                  <textarea required v-model="item.Detail.Mensaje" placeholder="Escriba su mensaje..." class="form-control" id="mensaje" rows="6"></textarea>
                </div>
              </div>
            </div>
            <div class="row px-5 mt-3">
              <div class="col-md-6">
                <input id="file-upload" type="file" accept="image/*" @change="handleFileUpload" />
              </div>
            </div>
            <div class="text-right px-5 pb-4">
              <button type="submit" class="mx-1 btn px-4 py-2 btn-primary">Enviar</button>
              <button class="mx-1 btn px-4 py-2 btn-secondary">Cancelar</button>
            </div>
          </card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
const Swal = require("sweetalert2");

export default {
  data() {
    return {
      ListaPrioridad: [
        { id: 1, nombre: "Baja" },
        { id: 2, nombre: "Media" },
        { id: 3, nombre: "Alta" },
      ],

      usuario: {},
      item: {
        General: {
          Id: null,
          Asunto: null,
          Fecha: null,
          PersonaId: null,
          Estado: 1,
          Prioridad: null,
          EmpresaId: null,
        },
        Detail: { Id: null, TicketId: null, urlAdjunto: null, DeId: null, ParaId: null, Mensaje: null, Fecha: null, Leido: false },
      },
      files: null,
      filelist: [],
      file1: null,
      filename1: null,
    };
  },
  mounted() {
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
      //this.uploadImagenPerfilUrl(file);
    },
    async renameFile(nameFile) {
      this.realName = nameFile;
      let collectionBad = "á é í ó ú Á É Í Ó Ú ñ Ñ";
      let collectionGood = "a e i o u A E I O U n N";
      let collectionBadA = collectionBad.split(" ");
      let collectionGoodA = collectionGood.split(" ");
      let count = 0;
      for await (let it of collectionBadA) {
        let pos = nameFile.indexOf(it);
        if (pos > 0) {
          nameFile = nameFile.replaceAll(it, collectionGoodA[count]);
        }
        pos = 0;
        count++;
      }
      return nameFile;
    },
    async uploadImagenPerfilUrl(ticketId) {
      try {
        this.$isLoading(true);
        //this.file = file; //event.target.files[0];
        this.filename = await this.renameFile(this.file.name); // Math.floor(Math.random() * 9999999999999 + 1);
        // console.log("aaaaaaaaaaaaaaa", this.file, this.filename);
        if (this.filename != null) {
          let url = ".|site1|users|tickets|" + ticketId;

          let uploadImg = await this.$store.dispatch("onFileCustom", {
            filename: this.filename,
            file: this.file,
            url: url,
          });

          if (uploadImg == true) {
            console.log("archivo subido...", uploadImg);

            // let data = {
            //   personaId: this.usuario.id,
            //   url: "https://www.helexiumfiles.com/users/tickets/" + ticketId + "/" + this.filename,
            // };
            return "https://www.helexiumfiles.com/users/tickets/" + ticketId + "/" + this.filename;
            // console.log(data);

            // try {
            //   await this.$store.dispatch("hl_post", {
            //     path: "Persona/UploadImagenPerfilUrl",
            //     data,
            //   });
            //   // console.log("urlImage", response);
            //   // Swal.fire("Listo!", "Se ha guardado la foto.", "success");
            //   Swal.fire({
            //     title: "Listo!",
            //     text: "Se ha guardado la foto.",
            //     icon: "success",
            //     showCancelButton: false,
            //     confirmButtonColor: "#3085d6",
            //     cancelButtonColor: "#d33",
            //     confirmButtonText: "OK",
            //     cancelButtonText: "Cancelar",
            //   }).then(async (result) => {
            //     if (result.isConfirmed) {
            //       this.getPersonaByIDAfterUploadImagenPerfil(data.personaId);
            //     }
            //   });
            // } catch (error) {
            //   console.error(error);
            // }
          }
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        Swal.fire("Error!", "Ha ocurrido un error al guardar la foto.", "error");
        console.log("errror upload", error);
      }
    },

    async saveData() {
      try {
        this.$isLoading(true);
        this.item.General.PersonaId = this.usuario.id;
        this.item.General.EmpresaId = this.usuario.empresa.id;
        this.item.General.Fecha = this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DDTHH:mm");

        this.item.Detail.DeId = this.usuario.id;
        this.item.Detail.Fecha = this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DDTHH:mm");
        console.log(this.item);
        let dataGeneral = await this.$store.dispatch("hl_post", {
          path: "PersonaTicket/NuevoTicketGeneral",
          data: this.item.General,
        });
        if (dataGeneral > 0) {
          let dataCorreo = {
            Correo: "info@helexium.cloud",
            Asunto: "Nuevo ticket: " + this.item.General.Asunto,
            Mensaje: this.item.Detail.Mensaje,
          };
          let resCorreo = await this.$store.dispatch("hl_post", {
            path: "Persona/EnvioNotificacionCorreoCustom",
            data: dataCorreo,
          });

          console.log(resCorreo);
          this.item.Detail.TicketId = dataGeneral;
          this.item.Detail.urlAdjunto = await this.uploadImagenPerfilUrl(dataGeneral);

          let dataDetalle = await this.$store.dispatch("hl_post", {
            path: "PersonaTicket/NuevoTicketDetalle",
            data: this.item.Detail,
          });

          if (dataGeneral > 0 && dataDetalle > 0) {
            this.$isLoading(false);
            this.$router.push("/helex/Tickets");
            Swal.fire("Listo!", "Ticket creado correctamente!!", "success");
          }
        } else {
          Swal.fire("Error!", "Intentalo nuevamente!!", "error");
        }
        this.$isLoading(false);

      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },
  },
};
</script>
